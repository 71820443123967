<template>
 <p class="subtitle-1">{{text}}</p>
</template>

<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "NoResultSnippet",
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    text: {
      type: String,
      default(){
        return ""
      }
    },
  },
}
</script>
